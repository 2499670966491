
    export const LISTITEMS: any[] = [
        {"url":"/accueil", "name":"Page", "icon":"ft-home"},
        {"url":"/lots", "name":"Lots", "icon":"ft-box"},
        {"url":"/produits", "name":"Produits", "icon":"ft-shopping-cart"},
        {"url":"/adherents", "name":"Adhérents", "icon":"ft-users"},
        {"url":"/fournisseurs", "name":"Fournisseurs", "icon":"ft-truck"},
        {"url":"/referencement-groupes", "name":"Référencement des groupes", "icon":"ft-tag"},
        {"url":"/attribution-marche-public", "name":"Attribution marchés public", "icon":"ft-tag"},
    ]

