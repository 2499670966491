import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { LISTITEMS } from '../data/template-search';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';


@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
    toggleFullScreen = false;
    toggleClass = "ft-maximize";
    placement = "bottom-right";
    logoUrl = 'assets/logo-wicat.png';
    menuPosition = 'Side';
    isSmallScreen = false;
    protected innerWidth: any;
    transparentBGClass = "";
    hideSidebar: boolean = true;
    public isCollapsed = true;
    layoutSub: Subscription;
    configSub: Subscription;

    @ViewChild('search') searchElement: ElementRef;
    @ViewChildren('searchResults') searchResults: QueryList<any>;

    @Output()
    toggleHideSidebar = new EventEmitter<Object>();

    @Output()
    seachTextEmpty = new EventEmitter<boolean>();

    @Output()
    toggleFullscreen = new EventEmitter<boolean>();

    listItems = [];
    routes: any;

    public config: any = {};

    constructor(
        public auth: AuthService,
        private layoutService: LayoutService,
        private router: Router,
        private configService: ConfigService, private cdr: ChangeDetectorRef) {
        this.config = this.configService.templateConf;
        this.innerWidth = window.innerWidth;
        this.routes;

        this.layoutSub = layoutService.toggleSidebar$.subscribe(
            isShow => {
                this.hideSidebar = !isShow;
            });

    }

    ngOnInit() {
        this.listItems = LISTITEMS;

        if (this.innerWidth < 1200) {
            this.isSmallScreen = true;
        }
        else {
            this.isSmallScreen = false;
        }
    }

    ngAfterViewInit() {
        this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
            if (templateConf) {
                this.config = templateConf;
            }
            this.loadLayout();
            this.cdr.markForCheck();
        })
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
        if (this.configSub) {
            this.configSub.unsubscribe();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
        if (this.innerWidth < 1200) {
            this.isSmallScreen = true;
        }
        else {
            this.isSmallScreen = false;
        }
    }

    loadLayout() {

        if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
            this.menuPosition = this.config.layout.menuPosition;
        }

        if (this.config.layout.variant === "Light") {
            this.logoUrl = 'assets/img/logo-dark.png';
        }
        else {
            this.logoUrl = 'assets/img/logo.png';
        }

        if (this.config.layout.variant === "Transparent") {
            this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
        }
        else {
            this.transparentBGClass = "";
        }

    }

    //   openMails(event: any){
    //     let url = '/mails';

    //     this.router.navigate([url]);

    //   }

    //   onSearchKey(event: any) {
    //     // if (this.searchResults && this.searchResults.length > 0) {
    //     //   this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    //     // }

    //     // if (event.target.value === "") {
    //     //   this.seachTextEmpty.emit(true);
    //     // }
    //     // else {
    //     //   this.seachTextEmpty.emit(false);
    //     // }
    //   }

    removeActiveClass() {
        if (this.searchResults && this.searchResults.length > 0) {
            this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
        }
    }

    ToggleClass() {
        this.toggleFullScreen = !this.toggleFullScreen;
        this.toggleFullscreen.emit(this.toggleFullScreen);
        // faire event emitter pour masquer sidebar 
        if (this.toggleClass === "ft-maximize") {
            this.toggleClass = "ft-minimize";
        } else {
            this.toggleClass = "ft-maximize";
        }
    }

    toggleNotificationSidebar() {
        this.layoutService.toggleNotificationSidebar(true);
    }

    toggleSidebar() {
        this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
    }

    logout(): void {
        this.auth.logout();
        this.router.navigate(['/pages/login'], { queryParams: { loggedOut: 'success' } });
    }
}
