export const baseURL = 'https://api.wigao.fr';
export let token = localStorage.getItem("auth_tkn");
export const optionsRequeteGet : any = {
    headers: {'Authorization': 'Bearer '+token},
    mode: 'cors'
  }
export const optionsRequetePost : any = {
  headers: {
    'Authorization': 'Bearer '+token,
    'Content-Type': 'application/json'
  },
  mode: 'cors'
}