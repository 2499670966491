<!-- <nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" -->
  <nav
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <!-- <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)"> -->
        <!-- <ul class="navbar-nav" >
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
        
      
      </ul> -->
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div><span
                    class="text">Wicat</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
     <!--<div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
 
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span
                class="notification badge badge-pill badge-danger">2</span></a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">2 nouvelles</span>
                  </div>
                  <span class="text-bold-400 cursor-pointer">Marquer tout comme lu</span>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <div class="avatar">
                          <span class="avatar-content">LD</span>
                      </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Appels d'offres</span><small class="grey lighten-1 font-italic float-right">8 jours</small>
                      </h6>
                      <small class="noti-text">Appel d'offre du 01/07/21</small>
                      <h6 class="noti-text font-small-3 text-bold-500 m-0">
                        L'appel d'offre a été diffusé
                      </h6>
                    </div>
                  </div>
                </a>
                <div class="d-flex justify-content-between cursor-pointer read-notification">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-info bg-lighten-4 mr-3 p-1">
                        <div class="avatar-content font-medium-2">
                          <i class="ft-align-left text-info"></i>
                        </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Le fournisseur "ASDF" n'existe plus</span><small class="grey lighten-1 font-italic float-right">1 jour</small>
                      </h6>
                    </div>
                  </div>
                </div>
              </li>
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                  Voir toutes les notifications
                </div>
              </li>
            </ul>
          </li> -->

<!-- UTILISATEUR -->
          <!-- <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="avatar mr-2">
                <span class="avatar-content"><i class="avatar-icon ft-user"></i></span>
            </div>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 " aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <div class="media-body p-1">
                <div>                 
                    <small class="grey lighten-1 font-italic">{{version}}</small>
                </div>
                <div>
                  <h6 class="noti-text font-small-3 text-bold-500 m-0">
                    {{groupe}}
                  </h6>
                </div>
                <div>
                  <h6 class="noti-text font-small-3 text-bold-500 m-0">
                    {{etablissement}}
                  </h6>
                </div>
                <div>
                  <h6 class="m-0">
                    <span>{{nm_user}}</span>
                  </h6>
                </div>
                <div>
                  <small class="grey lighten-1 font-italic">{{id_user}}</small>
                </div>
                <div>                
                    <small class="grey lighten-1 font-italic">{{profil}}</small>
                </div>
                <div>              
                    <small class="grey lighten-1 font-italic">Code app: </small><span>{{bdd}}</span>
                </div>             
              </div>


              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Déconnexion</span>
                </div>
             </a>
             
            </div>
          </li>
        </ul>
      </div>
    </div> -->
  </div>





</nav>
