import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseURL } from '../services/parametresRequetes';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwt = new JwtHelperService();

class DecodedToken {
    exp: number;
    username: string;
}


@Injectable()
export class AuthService {

    private decodedToken;

    constructor(
        public router: Router, private httpClient: HttpClient) {

    }

    //   signupUser(email: string, password: string) {
    //     //your code for signing up the new user
    //   }

    signinUser(email: string, password: string): Observable<any> {
        const options: any = {
            mode: 'cors',
            body: JSON.stringify({
                username: email,
                password: password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body: any = options.body;
        return this.httpClient.post(`${baseURL}/gettoken`, body, options)
            .pipe(map(token => {
                return this.saveToken(token);
            }));
    }

    private saveToken(token: any): any {
        this.decodedToken = jwt.decodeToken(token.access_token);
        localStorage.setItem('auth_tkn', token.access_token);
        localStorage.setItem('auth_meta', JSON.stringify(this.decodedToken));
        return token;
    }

    public logout(): void {
        localStorage.removeItem('auth_tkn');
        localStorage.removeItem('auth_meta');
        this.decodedToken = new DecodedToken();
    }

    isAuthenticated(): boolean {
        if (localStorage.getItem('auth_tkn')) {
            return true;
        }
        else return false;
    }
}
