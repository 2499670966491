import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '/accueil', title: 'Accueil', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/lots', title: 'Lots', icon: 'ft-box', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/produits', title: 'Produits', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/adherents', title: 'Adhérents', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/fournisseurs', title: 'Fournisseurs', icon: 'ft-truck', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: `Appels d'offres`, icon: 'ft-volume-1', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/appels-offres-redaction', title: 'Rédaction', icon: 'ft-edit-2 submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/appels-offres-reponses', title: 'Réponses', icon: 'ft-book-open submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/appels-offres-demandes-maj-list', title: 'Demandes de M-A-J', icon: 'ft-refresh-ccw submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '/referencement-groupes', title: 'Référencement des groupes', icon: 'ft-tag', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/attribution-marche-public', title: 'Attribution marché public', icon: 'ft-tag', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/suivi-litiges', title: 'Suivi litiges', icon: 'ft-feather', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: `Chiffre d'affaire`, icon: 'ft-activity', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/generation-demande-chiffre-affaire', title: 'Génération demande', icon: 'ft-edit-2 submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/integration-reponse-chiffre-affaire', title: 'Intégration réponse', icon: 'ft-book-open submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/demandes-sans-reponse', title: 'Demandes sans réponse', icon: 'ft-x-square submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/chiffre-affaire-fournisseur', title: 'Chiffre d\'affaire fournisseur', icon: 'ft-truck submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/chiffre-affaire-adherent', title: 'Chiffre d\'affaire adhérent', icon: 'ft-user submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/exporter-chiffre-affaire', title: 'Exporter chiffre d\'affaire', icon: 'ft-arrow-down-circle submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]

    },
    {
        path: '/exports', title: 'Exports excel', icon: 'ft-download', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },

    {
        path: '', title: `Paramètres`, icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/communes', title: 'Communes', icon: 'ft-map-pin submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/egalims', title: 'Egalims', icon: 'ft-tag submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/industriels', title: 'Industriels', icon: 'ft-home submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/marques', title: 'Marques', icon: 'ft-award submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/origines', title: 'Origines', icon: 'ft-flag submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/regions-departements', title: 'Régions/Départements', icon: 'ft-map submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/unites', title: 'Unites', icon: 'ft-codepen submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    }
];
