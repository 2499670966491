import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html"
})

// Composant général permettant d'insérer directement le titre de la page 
// ainsi qu'une url de retour et avec une mise en forme

export class HeaderComponent {
    @Input() titre !: any;
    @Output() retour = new EventEmitter();

    constructor(
    ) { }

    retourClick() {
        this.retour.emit(null);
    }

}