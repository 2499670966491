import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})

export class HandleErrorService {
    constructor(
        private toastr: ToastrService
    ) { }

    // Handling HTTP Errors using Toaster
    public handleError(err: HttpErrorResponse) {
        let errorMessage: any;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            switch (err.status) {
                case 400:
                    errorMessage = "Mauvaise requête.";
                    break;
                case 401:
                    errorMessage = "Informations d'authentification incorrectes.";
                    break;
                case 403:
                    errorMessage = "Accès non-autorisé.";
                    break;
                case 404:
                    errorMessage = "Le serveur n'a pas trouvé la ressource demandée.";
                    break;
                case 412:
                    errorMessage = "Precondition Failed.";
                    break;
                case 500:
                    errorMessage = "Erreur serveur.";
                    break;
                case 503:
                    errorMessage = "Service non disponible.";
                    break;
                case 422:
                    let arrayOfErrors = [];
                    var result = Object.keys(err.error).map(function (key) {
                        return [String(key), err.error[key]];
                    });
                    result.forEach((erreur) => {
                        arrayOfErrors.push(
                            {
                                field: `${erreur[0]}`,
                                message: `${erreur[1]}`
                            }
                        )
                    });
                    
                    let ul = "<ul>";
                    arrayOfErrors.forEach(errorMessage => {
                        ul = ul + `<li>${errorMessage.message}</li>`;
                    });
                    ul = ul + '</ul>';
                    errorMessage =
                    {
                        title: 'Erreur de validation !',
                        message: `${ul}`
                    }
                    break;
                default:
                    errorMessage = "Erreur !";
            }
        }
        if (errorMessage) {
            if(errorMessage.title){
                this.toastr.error(errorMessage.message, errorMessage.title, { enableHtml: true });
            }
            else this.toastr.error(errorMessage);
        }
    }




















    // getClientMessage(error : Error): string {

    //     if(!navigator.onLine){
    //         return 'Aucune connexion internet';
    //     }
    //     return error.message ? error.message : error.toString();
    // }
    // getClientStack(error: Error): string {        
    //     return error.stack;
    // }
    // getServerMessage(error: HttpErrorResponse): string {   
    //     return error.message;
    // }
    // getServerStack(error: HttpErrorResponse): string {   
    //     return 'stack';
    // }
}