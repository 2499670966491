import { ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { DialogService } from "./shared/auth/dialog.service";
import { ServerErrorInterceptor } from "./server-error.interceptor";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent
    ],
    imports: [
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        ToastContainerModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,        
        NgxSpinnerModule,
        PerfectScrollbarModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        WINDOW_PROVIDERS,
        DialogService,
        {provide: HTTP_INTERCEPTORS,
        useClass: ServerErrorInterceptor,
        multi:true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
