<div class="row ml-1 justify-content-between align-items-center">
  <div class="col-9">
    <div class="content-header ml-1">{{ titre }}</div>
  </div>

  <div class="col text-right align-self-start">
    <button
      class="btn btn-sm btn-outline-secondary align-middle mr-2 mt-1"
      (click)="retourClick()"
    >
      <i class="ft-arrow-left"></i>Retour
    </button>
  </div>
</div>
