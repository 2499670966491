import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { HROUTES } from './navigation-routes.config';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-horizontal-menu',
    templateUrl: './horizontal-menu.component.html',
    styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

    public menuItems: any[];
    public config: any = {};
    level: number = 0;
    transparentBGClass = "";
    menuPosition = 'Side';
    layoutSub: Subscription;

    constructor(
        private configService: ConfigService,
        private cdr: ChangeDetectorRef
    ) {
        this.config = this.configService.templateConf;
    }

    ngOnInit() {
        this.menuItems = HROUTES;
    }

    ngAfterViewInit() {
        this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
            if (templateConf) {
                this.config = templateConf;
            }
            this.loadLayout();
            this.cdr.markForCheck();
        })
    }

    loadLayout() {
        if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
            this.menuPosition = this.config.layout.menuPosition;
        }
        if (this.config.layout.variant === "Transparent") {
            this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
        }
        else {
            this.transparentBGClass = "";
        }
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }
}
