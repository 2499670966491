import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
  {
    path: '/page', title: 'Accueil', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  },
  {
    path: '/lots', title: 'Lots', icon: 'ft-box', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  },
  {
    path: '/produits', title: 'Produits', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/adherents', title: 'Adhérents', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/fournisseurs', title: 'Fournisseurs', icon: 'ft-truck', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/referencement-groupes', title: 'Référencement des groupes', icon: 'ft-tag', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/attribution-marche-public', title: 'Attribution marché public', icon: 'ft-tag', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
//   {
//     path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
//     submenu: [
//         { path: '/YOUR-ROUTE-PATH', title: 'Second Level', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//         {
//             path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
//             submenu: [
//                 { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//                 { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//             ]
//         },
//     ]
// },
];
