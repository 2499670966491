import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'accueil',
        loadChildren: () => import('../../pages/accueil/accueil.module').then(m => m.AccueilModule)
    },
    {
        path: 'lots',
        loadChildren: () => import('../../pages/lots/lot-list/lots.module').then(m => m.LotModule)
    },
    {
        path: 'lot/:id_lot',
        loadChildren: () => import('../../pages/lots/lot-edit/lot-edit.module').then(m => m.LotEditModule)
    },
    {
        path: 'produits',
        loadChildren: () => import('../../pages/produits/produits-list/produits.module').then(m => m.ProduitsModule)
    },
    {
        path: 'produit/:id_produit',
        loadChildren: () => import('../../pages/produits/produits-edit/produit-edit.module').then(m => m.ProduitEditModule)
    },

    {
        path: 'adherents',
        loadChildren: () => import('../../pages/adherents/adherents-list/adherents.module').then(m => m.AdherentsModule)
    },
    {
        path: 'adherent/:id_adherent',
        loadChildren: () => import('../../pages/adherents/adherents-edit/adherents-edit.module').then(m => m.AdherentsEditModule)
    },
    {
        path: 'fournisseurs',
        loadChildren: () => import('../../pages/fournisseurs/fournisseurs-list/fournisseurs.module').then(m => m.FournisseursModule)
    },

    {
        path: 'fournisseur/:id_fournisseur',
        loadChildren: () => import('../../pages/fournisseurs/fournisseur-edit/fournisseur-edit.module').then(m => m.FournisseurEditModule)
    },

    {
        path: 'appels-offres-redaction',
        loadChildren: () => import('../../pages/appels-offres/appels-offres-redaction/appels-offres-redaction.module').then(m => m.AppelsOffresRedactionModule)
    },
    {
        path: 'appels-offres-reponses',
        loadChildren: () => import('../../pages/appels-offres/appels-offres-reponses/appels-offres-reponses.module').then(m => m.AppelsOffresReponsesModule)
    },
    {
        path: 'appel-offre/:id_appel',
        loadChildren: () => import('../../pages/appels-offres/appel-offre-edit/appel-offre-edit.module').then(m => m.AppelOffreEditModule)
    },
    {
        path: 'appel-offre/:id_appel/groupes',
        loadChildren: () => import('../../pages/appels-offres/appel-offre-edit/appel-offre-edit-selection-groupes/appel-offre-edit-selection-groupes.module').then(m => m.AppelOffreEditGroupModule)
    },
    {
        path: 'appel-offre/:id_appel/lots',
        loadChildren: () => import('../../pages/appels-offres/appel-offre-edit/appel-offre-edit-selection-lots/appel-offre-edit-selection-lots.module').then(m => m.AppelOffreEditLotsModule)
    },
    {
        path: 'appel-offre/:id_appel/lots-fournisseurs',
        loadChildren: () => import('../../pages/appels-offres/appel-offre-edit/appel-offre-edit-selection-lots-fournisseurs/appel-offre-edit-selection-lots-fournisseurs.module').then(m => m.AppelOffreEditLotsFournisseursModule)
    },
    {
        path: 'appel-offre/:id_appel/adherents',
        loadChildren: () => import('../../pages/appels-offres/appel-offre-edit/appel-offre-edit-selection-adherents/appel-offre-edit-selection-adherents.module').then(m => m.AppelOffreEditAdherentsModule)
    },
    {
        path: 'appel-offre/:id_appel/generation-ao',
        loadChildren: () => import('../../pages/appels-offres/appel-offre-edit/appel-offre-edit-generation-ao/appel-offre-edit-generation-ao.module').then(m => m.AppelsOffresEditGenerationAppelModule)
    },
    {
        path: 'appel-offre/:id_appel/rao-txt',
        loadChildren: () => import('../../pages/appels-offres/appel-offre-edit/appel-offre-edit-text-accueil-rao/appel-offre-edit-text-accueil-rao.module').then(m => m.AppelOffreEditTextAccueilRAOModule)
    },
    {
        path: 'reponses-appel-offres/:id_appel',
        loadChildren: () => import('../../pages/appels-offres/reponses-appel-offres/reponses-appel-offres.module').then(m => m.ReponsesAppelOffresModule)
    },
    {
        path: 'appels-offres-demandes-maj-list',
        loadChildren: () => import('../../pages/appels-offres/appels-offres-demandes-maj/appels-offres-demandes-maj-list/appels-offres-demandes-maj-list.module').then(m => m.AppelsOffresDemandesMAJListModule)
    },
    {
        path: 'attribution-marche-public',
        loadChildren: () => import('../../pages/attribution-marche-public/attribution-marche-public.module').then(m => m.AttributionMarchePublicModule)
    },
    {
        path: 'referencement-groupes',
        loadChildren: () => import('../../pages/referencement-groupes/referencement-groupes.module').then(m => m.ReferencementGroupesModule)
    },
    {
        path: 'suivi-litiges',
        loadChildren: () => import('../../pages/suivi-litiges/suivi-litiges-list/suivi-litiges.module').then(m => m.SuiviLitigesModule)
    },
    {
        path: 'litige/:id_litige',
        loadChildren: () => import('../../pages/suivi-litiges/litige-edit/litige.module').then(m => m.LitigeModule)
    },
    {
        path: 'generation-demande-chiffre-affaire',
        loadChildren: () => import('../../pages/chiffre-affaire/generation-demande/generation-demande.module').then(m => m.GenerationDemandeModule)
    },
    {
        path: 'integration-reponse-chiffre-affaire',
        loadChildren: () => import('../../pages/chiffre-affaire/integration-reponse/integration-reponse.module').then(m => m.IntegrationReponseModule)
    },
    {
        path: 'demandes-sans-reponse',
        loadChildren: () => import('../../pages/chiffre-affaire/demandes-sans-reponse/demandes-sans-reponse.module').then(m => m.DemandesSansReponseModule)
    },
    {
        path: 'chiffre-affaire-fournisseur',
        loadChildren: () => import('../../pages/chiffre-affaire/chiffre-affaire-fournisseur/chiffre-affaire-fournisseur.module').then(m => m.ChiffreAffaireFournisseurModule)
    },
    {
        path: 'chiffre-affaire-adherent',
        loadChildren: () => import('../../pages/chiffre-affaire/chiffre-affaire-adherent/chiffre-affaire-adherent.module').then(m => m.ChiffreAffaireAdherentModule)
    },
    {
        path: 'exporter-chiffre-affaire',
        loadChildren: () => import('../../pages/chiffre-affaire/exporter-ca/exporter-ca.module').then(m => m.ExporterCADemandeModule)
    },
    {
        path: 'exports',
        loadChildren: () => import('../../pages/export-excel/export-excel.module').then(m => m.ExportExcelModule)
    },
    {
        path: 'communes',
        loadChildren: () => import('../../pages/parametres/communes/communes.module').then(m => m.CommunesModule)
    },
    {
        path: 'egalims',
        loadChildren: () => import('../../pages/parametres/egalims/egalims.module').then(m => m.EgalimsModule)
    },
    {
        path: 'industriels',
        loadChildren: () => import('../../pages/parametres/industriels/industriels.module').then(m => m.IndustrielsModule)
    },
    {
        path: 'marques',
        loadChildren: () => import('../../pages/parametres/marques/marques.module').then(m => m.MarquesModule)
    },
    {
        path: 'origines',
        loadChildren: () => import('../../pages/parametres/origines/origines.module').then(m => m.OriginesModule)
    },
    {
        path: 'origines',
        loadChildren: () => import('../../pages/parametres/origines/origines.module').then(m => m.OriginesModule)
    },
    {
        path: 'regions-departements',
        loadChildren: () => import('../../pages/parametres/regions-departements/regions-departements.module').then(m => m.RegionsDepartementsModule)
    },
    {
        path: 'unites',
        loadChildren: () => import('../../pages/parametres/unites/unites.module').then(m => m.UnitesModule)
    },
    
];
